body {
   margin: 0;
   font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
   sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }

html {
  font-family: "Montserrat", monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.white-content {
  background: #e4e4e4;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat-Regular'), url(./assets/fonts/Montserrat-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  src: local('Montserrat-Bold'), url(./assets/fonts/Montserrat-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'MisuseDisplay';
  font-style: normal;
  font-weight: normal;
  src: local('MisuseDisplay-Regular'), url(./assets/fonts/MisuseDisplay.ttf) format('truetype');
}

@font-face {
  font-family: 'Pasti';
  font-style: normal;
  font-weight: normal;
  src: local('Pasti-Regular'), url(./assets/fonts/Pasti.otf) format('opentype');
}

@font-face {
  font-family: 'DigitalDismay';
  font-style: normal;
  font-weight: normal;
  src: local('DigitalDismay'), url(./assets/fonts/DigitalDismay.otf) format('opentype');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: normal;
  src: local('OpenSans-Regular'), url(./assets/fonts/OpenSans-Regular.ttf) format('truetype');
}
