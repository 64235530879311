@import '~antd/dist/antd.css';

:root {
  --white: #fff;
  --black: #000000;
  --text-primary: #242223;
  --text-secondary: #f2efee;
  --text-ok: #1890ff;
  --background-primary: rgb(246, 246, 246);
  --background-secondary: #dad9d6;
  --background-sider: #b4262d;
  --background-sider-selected: #c4333a;
  --primary-1: #EA0115;
  --primary-2: #FD6600;
  --primary-3: #F1CF01;
  --primary-4: #020202;
  --primary-5: #1cb14b;
  --secondary-1: #EA0115;
  --secondary-2: #6100A2;
  --secondary-3: #FFFF08;
  --secondary-4: #03EF52;
  --secondary-5: rgba(231, 231, 23, 0.8);
}

body {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  -webkit-transform: translate3d(0, 0, 0);

  color: var(--text-primary);
  background-color: var(--background-primary);
  width: 100vw;
}

.ant-btn-primary {
  background-color: var(--primary-4);
  color: var(--white);
  border: 0;
  border-radius: 6px;
  padding: 4px 10px;
  font-weight: bold;
}

.ant-btn-primary:active, .ant-btn-primary:hover, .ant-btn-primary:focus {
  color: var(--white);
  background-color: #2d2d2d;
}


.ant-btn-default {
  border-radius: 6px;
  padding: 4px 10px;
  font-weight: bold;
}

a {
  color: var(--primary-1);
}

a:active {
  color: #bf0014;
}

a:hover, a:focus {
  color: var(--secondary-1);
}

.ant-btn-link {
  color: var(--primary-1);
}

.ant-btn-link:active {
  color: #bf0014;
}

.ant-btn-link:hover, .ant-btn-link:focus {
  color: var(--secondary-1);
}

.ant-btn-sm {
  height: inherit;
}

.ant-drawer {
  height: 100vh;
}

@supports (height: 100svh) {
  .ant-drawer {
    height: 100svh;
  }
}

.ant-drawer-body {
  background: #ea7025;
  padding: 0;
}

@media only screen and (min-height: 650px) and (min-width: 1200px) {
  .ant-drawer-close {
    width: 100%;
    color: white !important;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .ant-drawer-content {
    background: transparent;
  }

  .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }

  .ant-drawer-content-wrapper-hidden {
    transform: translateY(calc(100% - 50px));
    display: revert;
  }

  .closed-drawer {
    overflow: hidden;
  }

  .ant-drawer-header {
    padding: 0;
    background: transparent;
  }

  .ant-drawer-header-title {
    position: relative;
    height: 20px;
    background: #592c76;
  }

  .ant-drawer-panel-motion-bottom-leave {
    transform: translateY(50px) !important;
  }

  .ant-drawer-panel-motion-bottom-leave-active {
    transform: translateY(calc(100% - 50px)) !important;
  }

  .ant-drawer-content-wrapper-hidden {
    transform: translateY(calc(100% - 20px));
    display: revert;
  }

  .ant-drawer-panel-motion-bottom-leave {
    transform: translateY(20px) !important;
  }

  .ant-drawer-panel-motion-bottom-leave-active {
    transform: translateY(calc(100% - 20px)) !important;
  }

  .ant-drawer-body {
    background: white;
    padding: 24px;
  }

  .ant-drawer-wrapper-body {
    border-bottom: #592c76 15px solid;
    border-left: #592c76 15px solid;
    border-right: #592c76 15px solid;
  }

}

.ant-input:hover {
  border-color: var(--secondary-1);
}

.ant-input:focus, .ant-input-focused {
  border-color: var(--secondary-1);
  box-shadow: 0 0 0 2px rgba(234, 1, 21, 0.2);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--secondary-1);
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: var(--secondary-1);
  box-shadow: 0 0 0 2px rgba(234, 1, 21, 0.2);
}

.ant-input-number:focus, .ant-input-number-focused {
  border-color: var(--secondary-1);
  box-shadow: 0 0 0 2px rgba(234, 1, 21, 0.2);
}

.ant-input-number:hover {
  border-color: var(--secondary-1);
}

.ant-layout {
  background-color: var(--background-primary);
}

.ant-layout-header {
  background: var(--primary-1);
  color: var(--text-secondary) !important;
  line-height: 90px;
  height: 90px;
  padding: 0 8px;
}

.ant-layout-sider-children {
  background: var(--background-sider);
}

.ant-layout-sider-children > .ant-layout-header {
  background: var(--background-sider);
}

.ant-layout-sider-children > .ant-menu {
  top: 0;
}

.ant-list-item > span, .ant-upload {
  width: 100%;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: var(--background-sider-selected);
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: var(--background-sider-selected);
}

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
   color: var(--text-secondary) !important;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid var(--text-secondary) !important;
}

.ant-menu-horizontal {
  background-color: transparent;
  border-bottom: 0;
}

.ant-menu-inline {
  background-color: transparent;
}

.ant-menu-inline, .ant-menu-vertical {
  border: 0;
  color: var(--text-secondary) !important;
  font-weight: bold;
}

.ant-menu-item-selected {
  color: var(--text-secondary);
}

.ant-message-notice-content {
  font-family: "Pasti", serif;
  background-color: #e6c528;
  border-top: 3px solid var(--text-primary);
  border-bottom: 3px solid var(--text-primary);
  border-radius: 8px;
  line-height: 1;
  box-shadow: 0 4px 0 0 #af9037;
  margin-top: 95px;
}

.ant-modal-content {
  border-radius: 6px;
}

.ant-modal-footer > .ant-btn {
  padding: 4px 15px;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: var(--secondary-1);
}

.ant-picker-focused {
  border-color: var(--secondary-1);
  box-shadow: 0 0 0 2px rgba(234, 1, 21, 0.2);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--secondary-1);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--secondary-1);
  box-shadow: 0 0 0 2px rgba(234, 1, 21, 0.2);
}

.ant-tooltip-inner {
  color: black;
  max-width: 140px;
  border-radius: 10px;
}

.custom-input-suffix {
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.modal-title-img {
  width: 45%;
}

.modal-img {
  width: 19%;
}

@media only screen and (orientation: portrait) {
  .modal-title-img {
    width: 70%;
  }
  .modal-img {
    width: 40%;
  }
}

@media only screen and (max-width : 576px) and (orientation: portrait) {
  .modal-title-img {
    width: 90%;
  }
  .modal-img {
    width: 50%;
  }
}

.page-menu.ant-menu {
  background: transparent !important;
  width: 170px;
}

.page-menu.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: 20px;
}

.page-menu .ant-menu-item {
  background: var(--primary-5);
  color: var(--text-primary) !important;
  box-shadow: 1px 3px 0 1px #444;
  border-top: 2px solid #444;
  width: 147px;
  height: 42px;
  text-align: left;
  padding: 0 8px;
  font-weight: bold;
}

.page-menu .ant-menu-item-selected {
  background: #42ce6d !important;
}

.page-menu .ant-menu-item:hover,
.page-menu .ant-menu-item-active,
.page-menu .ant-menu-submenu .ant-menu-submenu-title:hover {
  background: #42ce6d !important;
}

@media only screen and (max-width: 991px) and (min-width: 576px) {
  .page-menu.ant-menu {
    width: 140px;
  }
  .page-menu .ant-menu-item {
    width: 120px;
    height: 40px;
    padding: 0 8px;
    font-size: 11px;
  }
}

@media only screen and (max-width : 700px) {
  .page-menu {
    width: auto;
    position: fixed;
    top: calc(90px / 2);
    left: calc(100% - 218px - 42px - 16px);
    transform: translateY(-50%);
    min-width: 218px
  }

  .page-menu .ant-menu-item {
    width: auto;
    min-width: 80px;
    height: 29px;
    padding: 10px 10px;
    font-size: 10px;
    box-shadow: 2px 0 0 1px #444;
    border-top: 0;
    border-left: 2px solid #444;
  }
  .page-menu .ant-menu-item {
    padding: 4px 6px !important;
  }
  .page-menu .ant-menu-item:first-child {
    margin-right: 15px;
  }
  .page-menu > .ant-menu-item > .ant-menu-title-content > div {
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: 2;
    align-items: center;
  }
  .page-menu,
  .ant-menu-horizontal > .ant-menu-submenu {
    padding: 4px 4px !important;
  }

  .page-menu > .ant-menu-item:hover::after,
  .page-menu > .ant-menu-submenu:hover::after,
  .page-menu > .ant-menu-item-active::after,
  .page-menu > .ant-menu-submenu-active::after,
  .page-menu > .ant-menu-item-open::after,
  .page-menu > .ant-menu-submenu-open::after,
  .page-menu > .ant-menu-item-selected::after,
  .page-menu > .ant-menu-submenu-selected::after {
    border-bottom-color: transparent !important;
    transition: none;
  }
}

.site-layout-content {
  text-align: left;
  min-height: 280px;
  max-width: 1700px;
  margin: 0 auto;
  padding: 20px 50px;
}

@media only screen and (max-width : 991px) and (orientation: portrait) {
  .site-layout-content {
    padding: 20px;
  }
}

@media only screen and (max-width : 700px) {
  .site-layout-content {
    padding: 20px;
  }
}

.site-page-header {
  border-bottom: 1px solid rgb(235, 237, 240);
  padding: 16px 0;
}

.section-header {
  padding: 16px 0;
}
.section-header, .ant-page-header-heading-title {
  font-size: 16px;
}

.the-number-position {
  font-size: 160px !important;
  margin: 22px 0 0;
  line-height: 100%;
}

@media only screen and (orientation: portrait) and (min-height : 1000px) {
  .the-number-position {
    font-size: 300px !important;
  }
}

@media only screen and (max-height : 600px) {
  .the-number-position {
    font-size: 100px !important;
  }
}

.the-number-loser-modal .modal-img {
  width: 20%;
}
.the-number-loser-modal .modal-title-img {
  width: 48%;
}

@media only screen and (orientation: portrait) {
  .the-number-loser-modal .ant-modal-body {
    padding: 60px 10px;
  }
  .the-number-loser-modal .modal-img {
    width: 50%;
  }
  .the-number-loser-modal .modal-title-img {
    width: 65%;
  }
}

@media only screen and (max-width : 576px) and (orientation: portrait) {
  .the-number-loser-modal .modal-title-img {
    width: 80%;
  }
}

.waiting-for-other-players-modal .modal-img {
  width: 25%;
}

@media only screen and (orientation: portrait) {
  .waiting-for-other-players-modal .ant-modal-body {
    padding: 60px 10px;
  }
  .waiting-for-other-players-modal .modal-title-img {
    width: 90%;
  }
  .waiting-for-other-players-modal .modal-img {
    width: 60%;
  }
}

@media only screen and (max-width : 576px) and (orientation: portrait) {
  .waiting-for-other-players-modal .modal-title-img {
    width: 90%;
  }
  .waiting-for-other-players-modal .modal-img {
    width: 60%;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

#bracket-canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.tournament {
  height: 100%;
  width: 100%;
}

.bracket-canvas {
  position: absolute;
  top: calc(24px + 20px);
  left: 24px;
}

.region {
  height: 95%;
  position: relative;
  width: 50%;
  float: left;
}

.user-stats .ant-table {
  background: transparent;
  font-weight: bold;
}

.user-stats .ant-table-row:first-child .ant-table-cell:nth-child(2) {
  border-right: var(--background-primary) solid 4px;
}

.user-stats .ant-table-row:first-child .ant-table-cell:nth-child(1),
.user-stats .ant-table-row:first-child .ant-table-cell:nth-child(2) {
  background: #d3d2d4;
}

.user-stats .ant-table-row:first-child .ant-table-cell:nth-child(3),
.user-stats .ant-table-row:first-child .ant-table-cell:nth-child(4) {
  background: #d4e3ba;
}

.user-stats .ant-table-tbody > tr > td {
  border-bottom: 0;
}

.ranking-table .ant-table {
  background: transparent;
}

.ranking-table .ant-table-tbody > tr > td {
  border-bottom: 0;
}

.darken {
  filter: brightness(70%);
}

.lighten {
  filter: brightness(110%);
}

.space-align-end {
  align-items: center;
}

@media only screen and (max-width: 720px) and (orientation: portrait) {
  .space-align-end {
    align-items: end;
  }
}

.space-align-start {
  align-items: center;
}

@media only screen and (max-width: 720px) and (orientation: portrait) {
  .space-align-start {
    align-items: start;
  }
}
